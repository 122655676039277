<template>
    <div class="app-container">
        <el-row :gutter="32" class="table-container">
            <el-col>
                <el-form :inline="true">
                    <el-form-item label="">
                        <el-input v-model="queryParams.keyword" placeholder="审批人"></el-input>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select v-model="queryParams.keyword" filterable placeholder="活动区域">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select v-model="queryParams.delState" filterable style="width: 140px" class="filter-item"
                                   @change="refreshTable">
                            <el-option v-for="item in sortOptions" :key="item.key" :label="item.label" :value="item.key"/>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" icon="el-icon-search"  @click="refreshTable">查询</el-button>
                    </el-form-item>
                    <el-button class="filter-item" type="primary" icon="el-icon-edit" @click="refreshTable">新增</el-button>
                </el-form>
            </el-col>
        </el-row>
        <el-row :gutter="32" class="table-container" style="background-color: #FFFFFF; padding: 40px 40px">
            <el-col :span="24" style="margin-bottom: 20px">
                <div v-html="diffData[0]"></div>
            </el-col>
            <el-col :span="24" style="margin-bottom: 20px">
                <div v-html="diffData[1]"></div>
            </el-col>
            <el-col :span="24" style="margin-bottom: 20px">
                <div v-html="diffData[2]"></div>
            </el-col>
        </el-row>


        <el-row :gutter="32" class="table-container">
            <el-col>
                <el-table
                    :key="tableKey"
                    v-loading="tableLoading"
                    :data="tableData"
                    border
                    fit
                    highlight-current-row
                    style="width: 100%;"
                    @sort-change="sortChange"
                >
                    <el-table-column label="ID" prop="id" align="left" width="280">
                        <template slot-scope="{row}">
                            <span>{{ row.articleTitle }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="日期" width="150px" align="center">
                        <template slot-scope="{row}">
                            <span>{{ row.createTime | parseTime('{y}-{m}-{d} {h}:{i}') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Title" min-width="150px">
                        <template slot-scope="{row}">
                            <span class="link-type" @click="toEdit(row.articleId)">{{ row.articleTitle }}</span>
                            <el-tag>{{ row.categoryId | typeFilter }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="Author" width="110px" align="center">
                        <template slot-scope="{row}">
                            <span>{{ row.articleAuthor }}</span>
                        </template>
                    </el-table-column>
                    <!--            <el-table-column v-if="showReviewer" label="Reviewer" width="110px" align="center">-->
                    <!--                <template slot-scope="{row}">-->
                    <!--                    <span style="color:red;">{{ row.reviewer }}</span>-->
                    <!--                </template>-->
                    <!--            </el-table-column>-->
                    <el-table-column label="Imp" width="80px">
                        <template slot-scope="{row}">
                            <svg-icon v-for="n in + row.delState+1" :key="n" icon-class="star" class="meta-item__icon"/>
                        </template>
                    </el-table-column>
                    <!--            <el-table-column label="Readings" align="center" width="95">-->
                    <!--                <template slot-scope="{row}">-->
                    <!--                    <span v-if="row.pageviews" class="link-type" @click="handleFetchPv(row.pageviews)">{{ row.pageviews }}</span>-->
                    <!--                    <span v-else>0</span>-->
                    <!--                </template>-->
                    <!--            </el-table-column>-->
                    <el-table-column label="Status" class-name="status-col" width="100">
                        <template slot-scope="{row}">
                            <el-tag :type="row.delState | statusFilter">
                                {{ row.delState }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="Actions" align="center" width="240" fixed="right" class-name="small-padding fixed-width">
                        <template slot-scope="{row, $index}">
                            <el-button type="primary" size="mini" @click="toEdit(row.articleId)">Edit</el-button>
                            <el-button v-if="row.delState!='1'" size="mini" type="success"
                                       @click="handleModifyStatus(row,'published')">Publish</el-button>
                            <el-button v-if="row.delState!==0" size="mini" @click="toEdit(row,'draft')">Draft</el-button>
                            <el-button v-if="row.delState!='1'" size="mini" type="danger" @click="toEdit(row,$index)">Delete</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <Pagination v-show="queryParams.totalRecord>0" :total="queryParams.totalRecord"
                            :page.sync="queryParams.pageNumber" :limit.sync="queryParams.pageSize"
                            @pagination="initTable"/>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    // import { parseTime } from '@/utils'
    import Pagination from '@/components/Pagination' // secondary package based on el-pagination

    const calendarTypeOptions = [
        { categoryId: '8580a080c61942c5a0b2ea589bfae061', categoryName: '新闻动态' },
        { categoryId: 'US', categoryName: 'USA' },
        { categoryId: 'JP', categoryName: 'Japan' },
        { categoryId: 'EU', categoryName: 'Eurozone' }
    ]
    // arr to obj, such as { CN : "China", US : "USA" }
    const calendarTypeKeyValue = calendarTypeOptions.reduce((acc, cur) => {
        acc[cur.categoryId] = cur.categoryName
        return acc
    }, {})

    export default {
        components: {
            Pagination
        },
        filters: {
            statusFilter(status) {
                const statusMap = {
                    0: 'success',
                    1: 'info',
                    2: 'danger'
                }
                return statusMap[status]
            },
            typeFilter(type) {
                return calendarTypeKeyValue[type]
            }
        },
        data() {
            return {
                queryParams: {
                    pageNumber: 0,
                    pageSize: 10,
                    totalRecord: 0,
                    keyword: '',
                    categoryId: '',
                    releaseFlag: '',
                    bannerStatus: '',
                    delState: '0',
                    sort: ''
                },
                tableKey: 0,
                tableData: [],
                tableLoading: false,
                sortOptions: [{
                    label: 'ID Ascending',
                    key: '+id'
                }, {
                    label: 'ID Descending',
                    key: '-id'
                }],
                statusOptions: ['published', 'draft', 'deleted'],
                info: {},
                diffData: []
            }
        },
        mounted() {
            this.initTable();
            this.diff();
        },
        methods: {
            diff: function () {
                this.tableLoading = true;
                this.apis.postForm('cms/article/diff')
                    .then((res) => {
                        if (res.code === 1) {
                            this.diffData = res.data;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            initTable: function () {
                this.tableLoading = true;
                this.apis.postForm('cms/article/query', this.queryParams)
                    .then((res) => {
                        if (res.code === 1) {
                            this.tableData = res.data;
                            this.queryParams.totalRecord = res.count;
                            console.log(res);
                            this.tableLoading = false;
                        } else {
                            this.tableLoading = false;
                            console.log('发生错误:' + res.msg);
                        }
                    }).catch((err) => {
                    this.tableLoading = false;
                    console.log(err)
                });
            },
            refreshTable: function () {
                this.queryParams.pageNumber = 0;
                this.initTable();
            },
            sortChange(data) {
                const { prop, order } = data;
                console.log(prop, order)
                // if (prop === 'id') {
                //     this.sortByID(order)
                // }
            },
            getSortClass: function(key) {
                const sort = this.queryParams.sort;
                return sort === `+${key}` ? 'ascending' : 'descending'
            },
            toAdd: function () {
                this.info = {
                    categoryId: this.queryParams.categoryId,
                    annexList: []
                };
                this.$nextTick(function() {
                    this.$refs.editor.setHtml("");
                });
                this.openDrawer = true;
            },
            saveData: async function (formRef) {
                this.$refs[formRef].validate((valid) => {
                    if (valid) {
                        let url = "cms/article/save";
                        this.info.articleContent = this.$refs.editor.getHtml();
                        this.info.createUid = this.appCookies.getCookieUID();
                        this.info.createUname = this.appCookies.getCookieUNAME() ;
                        this.info.updateUid = this.appCookies.getCookieUID();
                        this.info.updateUname = this.appCookies.getCookieUNAME() ;
                        // 保存数据
                        this.apis.postJson(url, this.info)
                            .then((res) => {
                                if (res.code === 1) {
                                    this.openDrawer = false;
                                    this.$Message.success({
                                        content: '保存成功',
                                        top: 400,
                                        duration: 2
                                    })
                                    this.initTable();
                                } else {
                                    this.$Message.success({
                                        content: res.msg,
                                        top: 400,
                                        duration: 2
                                    })
                                }
                            }).catch(err => {
                            console.log(err);
                        });
                    }
                })
            },
            toEdit: function (keyId) {
                this.apis.get('cms/article/getById?articleId=' + keyId)
                    .then((res) => {
                        console.log(res);
                        if (res.code === 1) {
                            this.info = res.data;
                            // this.$nextTick(function() {
                            //     this.$refs.editor.setHtml(this.info.articleContent);
                            // });
                            // this.openDrawer = true;
                        } else {
                            this.info = {}
                        }
                    }).catch(err => {
                    console.log(err);
                });
            },
        }
    }
</script>

<style lang="scss" scoped>
::v-deep .add {
    color: red;
}
</style>
